@import "../variables";

.load {
    min-height: 100px;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;

    & + div {
        height: 100vh;
        overflow: hidden;
    }
}

.load-icon {
    animation: linear load 2s infinite;
    margin: auto;
    width: 32px;
    height: 32px;
    svg path {
        fill: $primary-color;
    }
}

.load-icon_small-size {
    .load-icon {
        width: 25px;
        height: 25px;
    }
}

.load-icon-wrap {
    margin: auto;
}

@keyframes load {
    from {
        transform: rotate(0deg) scale(2);
    }
    to {
        transform: rotate(360deg) scale(2);
    }
}

.transparent-loader {
    position: absolute;
    width: calc(100% - 32px);
    border-radius: 5px;
    top: 1px;
    background-color: #eeeeeebb;
    z-index: 2;
    height: calc(100% - 2px);
}
.load.transparent-loader + div {
    height: auto;
}
