//metrics
$header-height: 60px;
$sidebar-width: 55px;
$sidebar-open-width: 240px;

//colors
$light-color: #FAFAFA;
$dark-color: #BBBBBB;
$warning-color: #FFD54F;
$primary-color: #27979D;
$primary-light-color: #BEE0E1;
$info-color: #2196F3;
$success-color: #8BC34A;
$secondary-color: #F6F6F6;
$danger-color: #EB1D82;
$red-color: #F44336;
$text-color: #444444;
$button-color: #2196F3;
$gray-color: #6D6D6D;
$light-grey-color: #B6B6B6;
$medium-grey-color: #999999;
$notification-new-color: #EEEEEE;
$soft-dark-color: #ced4da;

//bootstrap override
$body-color: $text-color;
$theme-colors: (
        light: $light-color,
        dark: $dark-color,
        primary: $primary-color,
        secondary: $secondary-color,
        danger: $danger-color,
        red: $red-color,
        info: $info-color,
        success: $success-color,
        warning: $warning-color
);
