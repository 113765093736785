@import '../variables';
@import '../mixins';

.cart-icon {
  line-height: 25px;

  .lnr-cart {
    color: $gray-color;
    font-size: 24px;
    margin-left: 8px;
  }

  .count {
    position: absolute;
    top: -7px;
    right: -7px;
    font-size: 11px;
    line-height: 11px;
    width: 13px;
    height: 13px;
    color: #ffffff;
    border-radius: 50%;
    padding-top: 2px;
  }
}

.cart {
  &-header {
    height: 56px;

    h1 {
      text-indent: 20px;
    }
  }

  &-product-list {
    h2 {
      text-indent: 20px;
    }

    &-line {
      padding: 8px 15px 8px 8px;
      border-bottom: 1px solid $dark-color;

      & .main {
        min-height: 74px;
      }

      &:nth-child(2) {
        border-top: 1px solid $dark-color;
      }
      &:last-child {
        border-bottom: none;
      }
      img {
        width: 60px;
      }

      .info {
        font-size: 14px;
        line-height: 25px;
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:last-child {
            font-weight: 350;
          }
        }
      }

      &-count {
        input,
        button {
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }
      }

      .price {
        line-height: 20px;

        .old-price {
          font-size: 12px;
          text-decoration-line: line-through;
        }
      }

      .delete {
        width: 30px;
        font-size: 17px;
      }

      .services {
        padding: 12px 12px 8px 60px;

        &-cost {
          line-height: 20px;
          margin-right: 30px;
        }
      }
      .small-height {
        height: 20px;
      }

      .delivery-select {
        height: 40px;
        background-color: $light-color;
        font-size: 16px;
        line-height: 24px;
        border-color: $dark-color;
        width: 203px;

        div.arrow {
          border-left: none;
          color: $text-color;
        }

        &.open div.arrow {
          background-color: transparent;
        }
      }

      .delivery-placeholder > div:first-child {
        color: $danger-color;
      }

      .delivery-placeholder {
        border: 1px solid $danger-color !important;
      }

      .delivery-placeholder div.arrow {
        color: $danger-color;
      }
    }
  }

  &-totals {
    padding: 8px;

    > div {
      padding: 15px 0;
      border-top: 2px solid $dark-color;

      .promo {
        line-height: 32px;

        input {
          border-radius: 5px 0 0 5px;
          width: 175px;
          height: 32px;
        }

        button {
          height: 32px;
          font-size: 18px;
        }

        .promoError {
          margin-top: 5px;
          line-height: 12px;
          display: inline-block;
          width: 175px;
        }
      }

      .totals {
        margin-right: calc(38px + 1.5rem);
        width: 37%;

        .d-flex {
          line-height: 25px;
        }
      }
    }
  }

  .select {
    font-size: 14px;
    line-height: 21px;
    padding-right: 30px;

    .dropdown {
      top: calc(100% + 1px);
    }

    .arrow {
      width: 30px;
      height: 30px;
      border-radius: 0 5px 5px 0;
      border: 0;
      border-left: 1px solid $primary-color;
      position: absolute;
      right: 0;
      margin: 0;

      &:before {
        content: '\e874';
        font-family: 'Linearicons-Free', sans-serif;
        width: 10px;
        height: 10px;
        font-size: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -10px;
        margin-left: -5px;
      }
    }

    &.open {
      border-radius: 5px;

      .arrow {
        background: $primary-color;
        margin-top: 10px;
      }
    }
  }
  &-count {
    background: $light-color;
    display: flex;
    border: 1px solid $dark-color;
    border-radius: 5px;
    justify-content: center;
    width: 130px;
    height: 40px;

    label {
      height: 100%;
      border: 1px solid $dark-color;
      border-top: none;
      border-bottom: none;
      margin-bottom: 0px;
      padding: 10px 20px;
    }
  }
  &-wizard {
    .btn {
      line-height: 20px;
    }

    &-body {
      padding: 10px 20px;
      min-height: 270px;
    }

    &-footer {
      button {
        width: auto;
        min-width: 194px;
        font-size: 14px;
      }
    }

    .divider {
      margin: 15px;
      border-bottom: 1px solid $dark-color;
    }

    /* STEP 1 */
    &-select-customer {
      .registration-btn {
        min-width: 150px;
      }

      .search {
        .search-suggestions {
          background-color: $light-color;
          top: 43px;
        }
      }

      .search-form .form-control {
        height: calc(1.5em + 0.75rem + 8.5px);
        font-size: 14px;
        line-height: 20px;
      }

      .customer-info {
        border: 1px solid $dark-color;
        padding: 10px 0;
        .row:not(:last-child) div:first-child {
          line-height: 40px;
        }
      }

      input {
        height: 40px;
        border-radius: 5px;
      }
    }

    /* STEP 2 */
    &-delivery {
      .need-lift {
        top: -12px;
      }
      .select {
        .arrow {
          border-left: 0;
          height: 22px;
        }
        &.open {
          border-radius: 5px;

          .dropdown {
            max-height: 192px;
          }

          .dropdown.dropdown-with-scroll {
            overflow-y: scroll;
          }

          .dropdown.dropdown-with-scroll::-webkit-scrollbar {
            width: 8px;
          }
           
          .dropdown.dropdown-with-scroll::-webkit-scrollbar-thumb {
            border-radius: 16px;
            background-color:$medium-grey-color;
          }

          .arrow {
            background: $light-color;

            &:before {
              color: $text-color;
            }
          }

          .dropdown-item:not(:last-child) {
            border-bottom: 1px solid #f2f2f2;
          }
        }
      }
      .input-row input,
      .select,
      .dropdown {
        background-color: $light-color !important;

      }
      .dropdown-item.selected {
        background-color: #f2f2f2;
      }

      .date-change-block {
        min-height: 35px;
      }

      .date-change-block--tall {
        min-height: 100px;
      }

      .transparent-loader {
        width: calc(100% + 10px);
        left: -5px;
        height: calc(100% + 8px);
      }

      .date-picker-wrapper {
        min-width: 112px;
        height: 35px;
      }
    }
    /* STEP 3 */
    &-additional-info {
      textarea {
        width: 100%;
      }

      .divider {
        margin: 15px 0;
      }

      .select {
        margin-left: 35px;
      }

      .search {
        padding-right: 15px;
        padding-left: 15px;
        .search-suggestions {
          background-color: $light-color;
          top: 43px;
          width: calc(100% - 30px);
          margin-left: 15px;
          max-width: 100%;
        }
      }

      .search-form .form-control {
        height: calc(1.5em + 0.75rem + 8.5px);
        font-size: 14px;
        line-height: 20px;
      }
      .custom-control-label::before {
        top: 0.75rem;
      }

      .custom-control-label::after {
        top: calc(0.75rem + 2px);
      }

      .save-button-on-top {
        position: absolute;
        top: 0px;
        right: 15px;
        z-index: 1000;
      }

      .relation-types {
        margin-left: 0;
      }

      input {
        height: 29px;
      }
    }
  }
}

.customer-container {
  & .form-group {
    display: flex;
    flex-direction: column;
    padding-bottom: 0;
    min-height: auto;

    & .text-danger {
      position: relative;
    }
    & .text-red {
      position: relative;
    }
  }
}

.empty-cart-block {
  min-height: 270px;
}

.text-primary {
  color: $primary-color;
}

.btn-edit-client {
  @include respond(tab-port) {
    margin-left: -45px;
    min-width: 150px;
  }
}
