.countElement {
  position: absolute;
  font-size: 11px;
  line-height: 11px;
  width: 15px;
  height: 15px;
  color: #FFFFFF;
  border-radius: 50%;
  padding-top: 2px;
  }
