@import "../../styles/variables";

.countAndPrice {
  align-self: flex-start;
  position: relative;
}

.countAndSpecialPrice {
  align-self: flex-start;
  position: relative;
  padding-top: 17px;
}

.countAndSpecialPriceOnLoading {
  padding-top: 17px;
}

.cartProductPrice {
  white-space: nowrap;
  position: absolute;
  width: 100%;
  left: 0;
  top: 113%;
}

.waitingItem {
  padding: 20px 15px 8px 8px;
  color: $medium-grey-color;

  &:first-child {
    border-top: 1px solid $dark-color;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $dark-color;
  }
}

.trashButton {
  color: $text-color;
  font-size: 17px;
}

.waitingItemImage {
  width: 60px;
}


.waitingItemLink {
  color: $medium-grey-color;
}

.statusAlert {
  position: relative;
  margin-right: auto;
  border-radius: 4px;
  padding-left: 40px;
  padding-right: 12px;
  height: 32px;
  display: flex;
  align-items: center;
  font-size: 12px;
}

.statusAlertProcess {
  background-color: #E5E5E5;
  color: $text-color;
}

.statusAlertError {
  background-color: $danger-color;
  color: #FFFFFF;
}

.statusAlertIcon {
  position: absolute;
  top: 7px;
  left: 13px;
}

.cartTotalWaitingItems {
  margin-top: 12px;
  color: $medium-grey-color;
}

.attributesToggleButton {
  color: $primary-color;
  border: none;
  background-color: transparent;
  padding: 0;
  height: 25px;
}

.attributesToggleOpened:before {
  content: "\e873";
  margin-left: 55px;
  margin-top: 1px;
  font-family: 'Linearicons-Free', sans-serif;
  width: 10px;
  height: 10px;
  font-size: 10px;
  position: absolute;
}

.attributesToggleClosed:before {
  content: "\e874";
  margin-left: 85px;
  margin-top: 1px;
  font-family: 'Linearicons-Free', sans-serif;
  width: 10px;
  height: 10px;
  font-size: 10px;
  position: absolute;
}

.attribute {
  color: $medium-grey-color;
}

.attributeLabel {
  font-weight: 700;
}

.attributeValue {
  padding-left: 4px;
}

.attributeValueExpanded {
  position: absolute;
  white-space: nowrap;
}

.attributeImage {
  max-width: 20px;
  margin-top: -5px;
  margin-right: 5px;
  margin-left: 5px;
}