@import "../variables";

.nav-tabs {
    .nav-link {
        &:hover {
            border-color: $secondary-color;
        }

        &.active, &.show {
            background-color: $secondary-color;
            border-radius: 0;
            border-color: $primary-color $dark-color $secondary-color;
            border-top-width: 2px;
        }
    }
}

.tab-content {
    padding: 15px 0;
}

.propertyName {
    font-weight: 700;
    font-size: 14px;
  }
  .propertyValue {
    font-weight: 400;
    font-size: 14px;
  }
  