@import "../variables";

.slider {
  padding-top: 30px;

  &-col.col {
    padding-left: 12px;
    padding-right: 12px;
  }

  .rc-slider-disabled {
    background-color: transparent;
  }

  .rc-slider-mark-text {
    font-size: 14px;
    transform: translate3d(-50%, -48px, 0) !important;
  }

  &--no-inner-mark-text {
    .rc-slider-mark-text {
      display: none;
    }
    .rc-slider-mark-text:first-child {
      display: inline-block;
    }
    .rc-slider-mark-text:last-child {
      display: inline-block;
    }
  }

  .rc-slider-track {
    background-color: $primary-light-color;
    height: 8px;
  }

  .rc-slider-rail {
    height: 8px;
  }

  .rc-slider-handle {
    width: 24px;
    height: 24px;
    background: $primary-color;
    border: none;
    margin-top: -8px;
  }

  .rc-slider-step {
    display: none;
  }

  &--with-steps {
    .rc-slider-step {
      display: block;
    }

    .rc-slider-dot {
      width: 16px;
      height: 16px;
      bottom: -8px;
      background-color: #e9e9e9;
    }

    .rc-slider-dot-active {
      background-color: #BEE0E1;
      border-color: #BEE0E1;
    }

    .rc-slider-disabled .rc-slider-dot-active,
    .rc-slider-disabled .rc-slider-dot  {
      border-color: #e3e3e3;
      background-color: #e3e3e3;
    }

    .rc-slider-disabled .rc-slider-track {
      background-color: #e3e3e3;
    }
  }

  .rc-slider-disabled .rc-slider-handle {
    background-color: #e3e3e3;
  }

  &--with-one-option {
    .rc-slider-handle {
      left: 0 !important;
      background-color: #e3e3e3;
    }

    .rc-slider-dot-active {
      display: none;
    }

    .rc-slider-mark-text {
      left: 0 !important;
    }

    .rc-slider-track {
      width: 0 !important;
    }
  }
}