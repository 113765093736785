.dadata-suggestions {
    position: absolute;
    top: 100%;
    width: 100%;
    padding: 15px;
    z-index: 101;
    margin-top: -1px;
    max-height: 200px;
    overflow-x: auto;

    > div {
        padding-bottom: 5px;

        &:last-child {
            padding-bottom: 0;
        }
    }
}
