@import "../variables";
@import "../mixins";

.product {
    width: 190px;
    margin: 0 auto 40px;

    .img-container {
        height: 190px;
        width: 190px;
        background: #FFFFFF;
    }

    .title-wrap {
        min-height: 45px;
        margin: 10px 0 5px 0;

        .title {
            font-size: 18px;
            line-height: 20px;
            white-space: pre-wrap;
        }
    }

    .info {
        height: 45px;
        overflow: hidden;

        span {
            font-size: 10px;
            line-height: 15px;
            height: 15px;
            @include ellipsis();

            &.name {
                font-weight: 350;
            }

            &.value {
                max-width: 50%;
                margin-top: 0;
            }
        }
    }

    .colors {
        div {
            width: 10px;
            height: 10px;
            margin-right: 6px;
        }
    }

    button {
        font-size: 12px;
        line-height: 18px;
        color: $button-color;
        width: 86px;
        height: 20px;
        background: #FFFFFF;
        border: 1px solid $button-color;
        box-sizing: border-box;
        border-radius: 5px;
    }
}
