@import "../variables";

.card-button {
  height: 87px;
  width: 184px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: $secondary-color;
  cursor: pointer;

  >span {
    font-weight: 700;
    text-align: center;
  }

  &.selected {
    background-color: #fff;
    border: $primary-color 2px solid;
  }
}