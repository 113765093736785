@import "../variables";

.input-radio-container {
    position: relative;
    padding-left: 25px;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark-radio {
            background-image: url('../../images/icons/radio-active.svg');
        }

        &:disabled ~ .checkmark-radio {
            opacity: 0.5;
        }
    }
}

.checkmark-radio {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    background-image: url('../../images/icons/radio-inactive.svg');
}
