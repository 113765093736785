@import "../variables";
@import "../mixins";

.search {
    position: relative;

    &-form {
        input {
            @include ellipsis();
            position: relative;
            padding: 0 45px 0 15px;
        }

        .form-control {
            height: 62px;
            font-size: 20px;
            line-height: 20px;

            &:focus {
                box-shadow: none;
                border-color: $primary-color !important;
            }
        }

        .search-icon {
            position: absolute;
            right: 0;
            top: 0;
            width: 50px;
            display: flex;
            height: 100%;
            align-items: center;
            justify-content: flex-start;
            font-size: 20px;
            color: $gray-color;

            img {
                height: 20px;
            }
        }
    }

    &-suggestions {
        font-size: 18px;
        line-height: 27px;
        position: absolute;
        left: 0;
        top: 70px;
        z-index: 102;

        &-queries {
            padding: 0 20px;

            a {
                color: $text-color;
                text-decoration: none;
                font-size: 18px;
                line-height: 27px;
                height: 45px;
                display: flex;
                align-items: center;
            }
        }

        &-products, &-customers {
            border-top: 1px solid #EEEEEE;
            padding: 5px 0;

            .suggestion {
                color: $text-color;
                padding: 5px 20px;

                .ellipsis {
                    flex: 1;
                    @include ellipsis()
                }

                &-title {
                    font-size: 18px;
                    line-height: 27px;
                    min-width: 0;
                }

                &-description {
                    font-weight: 350;
                    font-size: 14px;
                    line-height: 21px;
                    @include ellipsis()
                }

                img {
                    margin-right: 10px;
                    width: 60px;
                    height: 60px;
                }
            }
        }

        &-all {
            height: 45px;
            padding: 0 20px;
        }
    }

    &-result {
        &-text {
            font-size: 16px;
            line-height: 24px;
            padding-bottom: 10px;
        }

        .button-show-more {
            height: 43px;
            margin-top: 27px;
        }

        .loader-show-more {
            height: 90px;
        }

        .button-up {
            height: 43px;
            margin: 16px 0;
            color: $gray-color;
            border: 1px solid #E7E2E2;
        }

        .customers {
            td, th {
                border-top: 0;
            }

            td {
                border-bottom: 1px solid;
                word-break: break-word;
            }

            td:first-child {
                padding-left: 20px;
            }

            td:last-child {
                padding-right: 20px;
            }
        }

        .orders {

            td, th {
                border-top: 0;
                padding: 0;
                text-align: center;
                min-height: 45px;
                display: flex;
                flex-direction: column;
                word-break: break-word;

                &.id {
                    flex: 0 0 11.42857%;
                    max-width: 11.42857%;
                }

                &.date-1 {
                    flex: 0 0 12.85714%;
                    max-width: 12.85714%;
                }

                &.fullname {
                    flex: 0 0 24.28571%;
                    max-width: 24.28571%;
                }

                &.items-length {
                    flex: 0 0 11.42857%;
                    max-width: 11.42857%;
                }

                &.grand-total {
                    flex: 0 0 11.42857%;
                    max-width: 11.42857%;
                }

                &.date-2 {
                    flex: 0 0 11.42857%;
                    max-width: 11.42857%;
                }

                &.status {
                    flex: 0 0 17.14285%;
                    max-width: 17.14285%;
                }
            }

            th {
                line-height: 15px;
            }

            td {
                border-bottom: 1px solid;
                line-height: 20px;
                justify-content: center;
            }
        }
    }
}
