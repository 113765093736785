.address {
    img {
        margin-left: -18px;
    }

    .address-block {
        position: relative;

        .address-detail {
            width: 23%;
        }
        
        .address-elevator {
            height: 40px;
            font-size: 1rem;
            border-radius: 5px;
            border: 1px solid $dark-color;
            background-color: $light-color;
        }
        .address-elevator.disabled {
            background-color: #e9ecef;
        }
    }
    .input-row {
        width: calc(100% - 275px); //radio, title to left and trash to right
        display: flex;
        position: relative;

        input:disabled {
            opacity: 0.5;
        }
    }
    .select {
        border-radius: 0;
        height: 24px;
        padding: 0 15px;
        min-width: auto;
    }
    .selected {
        justify-content: space-between;
        width: 100%;
    }
    .select .dropdown,
    .select.open {
        border-radius: 0;
    }
    .title-with-icon {
        width: 175px;
        margin-left: 18px;
    }

    .title-without-icon {
        width: 175px;
        margin-left: 43px;
    }

    .divider {
        margin: 14px 0 20px 20px;
        border-bottom: 1px solid $dark-color;
    }

    .remove-address-button {
        position: absolute;
        right: 0;
        top: calc(50% - 20px);
    }
    input:not([type='checkbox']) {
        position: relative;
        height: 40px;
        border-radius: 5px;
        margin-bottom: 24px;
    }
}
