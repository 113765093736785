@import "../variables";

.customer {
    position: relative;
    padding: 5px;

    &-profile,
    &-profile-edit {
        .btn-edit {
            height: 35px;
        }
        input:not([type='checkbox']) {
            position: relative;
            height: 24px;
            border-radius: 5px;
        }

        .title {
            align-self: center;
        }

        .info-block {
            height: 100%;
            padding: 16px;
            border-radius: 5px;

            img {
                //width: 14px;
                margin-right: 5px;
                margin-top: -4px;
            }

            &-title {
                width: 125px;
            }

            &-title.birth-date {
                line-height: 40px;
            }

            input {
                height: 40px;
            }
        }
        .customer-success {
            display: flex;
            flex-direction: column;
            width: 25%;
            margin-right: 0;
            margin-left: auto;
            align-items: center;
            visibility: hidden;
        }
        .big-input {
            height: 38px;
        }
        .contact-info-edit {
            width: 30%;
            line-height: 40px;

            input:not([type='checkbox']) {
                text-align: right;
            }
        }
        .subscriptions {
            margin-top: 28px;

            .contact-info-edit {
                line-height: 20px;
            }
        }
        .address-block input[type="text"] {
            background-color: $light-color;
        }
        .address-block .address-detail {
            width: 23%;
            .address-elevator {
                height: 40px;
                font-size: 1rem;
                border-radius: 5px;
                border: 1px solid $soft-dark-color;
                background-color: $light-color;
            }
            .address-elevator.disabled {
                background-color: $light-color;
            }
        }
    }
    &-profile {
        .address {
            max-height: 335px;
        } 
    }

    &-profile-edit {
        .save-button,
        .cancel-button {
            width: 145px;
        }
        .add-address-button {
            width: 194px;
        }
    }

    .common-button {
        height: 35px;
        font-size: 14px;
        line-height: 21px;
    }

    .main-info {
        input, button {
            height: 40px;
        }
    }
}
