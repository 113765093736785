.select {
    height: 31px;
    border: 1px solid #CCCCCC;
    box-sizing: border-box;
    border-radius: 5px;
    position: relative;
    min-width: 130px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    line-height: 14px;

    &.disabled {
        opacity: 0.5;
        cursor: default;
    }

    .arrow {
        width: 8px;
        height: 4px;
        border: 4px solid transparent;
        border-top: 4px solid #666666;
        margin-left: 11px;
        margin-top: 4px;
        transition: all 0.3s ease-out;
    }

    &.open .arrow.rotatable {
      transform: rotate(180deg) translateY(4px);
    }

    .dropdown {
        visibility: hidden;
        position: absolute;
        top: 100%;
        border-radius: 0 0 5px 5px;
        min-width: calc(100% + 2px);
        left: -1px;

        > div {
            padding: 10px 15px 10px 12px;
            display: flex;
            align-items: center;
            min-width: max-content;

            &:last-child {
                border-radius: 0 0 5px 5px;
            }

            &.selected {
                background: #EEEEEE;
            }
        }
    }

    &.open {
        border-radius: 5px 5px 0 0;

        .dropdown {
            visibility: visible;
            z-index: 101;
        }
    }
}
