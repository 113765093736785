@import "../variables";

.header {
    position: fixed;
    top: 0;
    height: $header-height;
    z-index: 102;
    box-shadow: 0 1px 12.5px rgba(0, 0, 0, 0.25);

    &-wrapper {
        position: relative;
        display: flex;
        height: $header-height;
    }

    &-button {
        width: $sidebar-width;
        height: $header-height;
        border: 0;
        transition: 0.3s;
        position: relative;
        background: $light-color;
        font-size: 18px;
    }

    &-left {
        left: 0;
        position: absolute;
        display: flex;
        height: 100%;
        width: 50%;
    }

    &-right {
        right: 0;
        position: absolute;
        display: flex;
        height: 100%;

        .user {
            margin-left: 33px;
            padding-left: 4px;

            &-icon {
                width: 36px;
                height: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #C4C4C4;
                border-radius: 50%;
                margin-right: 10px;
            }

            &-button {
                display: flex;
                align-items: center;
                border: 0;
                font-weight: 350;
                color: $text-color;
            }
        }
    }

    &:before {
        content: '';
        width: $sidebar-width;
        height: 12px;
        position: absolute;
        top: $header-height;
        left: 0;
        background: $light-color;
        transition: width 0.3s;
    }

    &.open {
        &:before {
            width: 0;
        }
    }

    &-menu {
        z-index: 101;
        position: absolute;
        width: 100%;
        min-width: 210px;
        border-radius: 0;
        border: none;
        top: $header-height;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s;

        &.show {
            max-height: 100px;
        }

        &-link {
            display: flex;
            padding: 9px 20px;
            height: 32px;
            width: 100%;
            position: relative;
            cursor: pointer;
            color: $text-color;
            font-weight: 350;
            font-size: 14px;
            line-height: 21px;
            align-items: center;
        }
    }
    &-color-gray {
        color: $gray-color
    }
}
