@import 'variables';
@import '~bootstrap/scss/bootstrap.scss';

@import 'components/loader';
@import 'components/modal';
@import 'components/header';
@import 'components/sidebar';
@import 'components/footer';
@import 'components/tabs';
@import 'components/carousel';
@import 'components/checkbox';
@import 'components/radio';
@import 'components/dadata';
@import 'components/select';
@import 'components/address';
@import 'components/date-picker';
@import 'components/tooltip';

@import 'screens/search';
@import 'screens/product';
@import 'screens/catalog';
@import 'screens/offer';
@import 'screens/fabrics';
@import 'screens/cart';
@import 'screens/customer';

@import 'fonts/fonts';

* {
  outline: none !important;
}

html,
body,
#root {
  height: 100%;
  font-family: Circe, sans-serif;
  font-size: 14px;
}

h1,
.h1 {
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
}

h2,
.h2 {
  line-height: 45px;
  font-weight: bold;
  font-size: 20px;
}

.main-content {
  padding: $header-height + 20 20px 20px $sidebar-width + 20;
  transition: padding-left 0.3s;
}

.cursor-pointer {
  cursor: pointer;
}

.currency {
  position: relative;
  display: inline-block;
  width: 10px;
  height: 26px;
  margin-left: 5px;

  &:before {
    content: '\20BD';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.back {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background: transparent;
  border: none;
}

.text-sm {
  font-size: 12px;
}

.text-lg {
  font-size: 16px;
}

.text-xl {
  font-size: 20px;
}

.text-xxl {
  font-size: 24px;
}

.br-5 {
  border-radius: 5px;
}

.pt-25 {
  padding-top: 25px;
}

.square {
  border-radius: 0;
}

.text-gray {
  color: $gray-color;
}

.text-regular {
  color: $text-color;
}

.text-medium-grey {
  color: $medium-grey-color;
}

.ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
  min-width: 0;
}

.underline {
  text-decoration: underline;
}

.object-fit-cover {
  object-fit: cover;
}

.pointer-events-none {
  pointer-events: none;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.height-40 {
  height: 40px !important;
}

.height-48 {
  height: 48px !important;
}

.new-address-add {
  margin-top: 5px;
  margin-bottom: 0px;
  color: $primary-color;
}
