@import "../variables";

.footer {
    flex-shrink: 0;
    height: 112px;
    padding-left: $sidebar-width;
    transition: padding-left 0.3s;

    &-top {
        height: 50px;
        border-bottom: 1px solid #E5E5E5;
    }

    .copy {
        font-weight: 350;
        font-size: 12px;
        line-height: 15px;
    }
}
