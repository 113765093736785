.offer {
    .condition {
        font-size: 12px;
        line-height: 18px;
        margin-left: 15px;
        position: relative;

        &.open:before {
            content: '-';
        }

        &:before {
            content: '+';
            position: absolute;
            font-weight: bold;
            font-size: 18px;
            line-height: 27px;
            color: rgba(0, 0, 0, 0.25);
            left: -12px;
            top: -4px;
        }
    }
}
