@import "../variables";

.input-checkbox-container {
    position: relative;
    padding-left: 24px;
    margin-bottom: 0;
    cursor: pointer;
    user-select: none;

    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;

        &:checked ~ .checkmark {
            background-image: url('../../images/icons/checked.svg');
            background-position: center;
            background-repeat: no-repeat;
        }

        &:disabled ~ .checkmark {
            opacity: 0.5;
        }
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        width: 20px;
        height: 20px;
        border-radius: 3px;
        background: $primary-color;
    }
}

.input-checkbox-container.checkmark_big-size {
    line-height: 26px;

    .checkmark {
        width: 24px;
        height: 24px;
    }
}
