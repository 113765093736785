@import '../../styles/variables';

.table {
  tr {
    border-bottom: 1px solid $dark-color;
  }

  th,
  td {
    padding: 15px 0;
  }
}

.statusIcon {
  font-size: 50px;
}

.statusIconLoading {
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.radioLabel {
  margin-bottom: 0.5rem;
}
