@import "../variables";

.sidebar {
    position: fixed;
    bottom: 0;
    top: 0;
    z-index: 99;
    height: 100vh;
    width: $sidebar-width;
    box-shadow: 0 1px 30px rgba(0, 0, 0, 0.25);
    transition: width 0.3s;

    &.open {
        width: $sidebar-open-width;

        ul {
            li {
                width: $sidebar-open-width;
            }
        }

    }

    ul {
        margin-top: $header-height + 15px;

        li {
            height: 40px;
            width: $sidebar-width;
            position: relative;
            transition-duration: 0s;
            transition-delay: 0s;
            cursor: pointer;
            overflow: hidden;
            background: transparent;
            border: none;
            transition: all 0.3s;

            a {
                color: $text-color;
                text-decoration: none;
                padding: 10px 15px;
            }

            p {
                margin: 0 0 0 25px;
                line-height: 26px;
                white-space: nowrap;
            }
        }
    }
}
