@import "../variables";
@import "../mixins";
@import "../functions";

.product-card {
    h1 {
        font-weight: bold;
        font-size: 24px;
        line-height: 35px;
    }

    .article {
        font-size: 12px;
        line-height: 18px;
    }

    .breadcrumbs {
        font-size: 16px;
        line-height: 24px;
    }

    .tabs {
        width: 300px;
        white-space: pre-wrap;

        .nav-tabs {
            font-size: 14px;
        }

        .tab-content {
            font-size: 12px;
            line-height: 18px;
            padding-right: 5px;

            .table-sm {
                td {
                    font-size: 10px;
                    line-height: 15px;
                    padding: 0 5px;
                }
            }
        }
    }

    .constructor-size {
        .input-container {
            input {
                height: 40px;
            }
        }
    }

    .stickers {
        position: absolute;
        top: -0.5rem;
        left: 15px;

        > div {
            &.sale {
                background: #F56B7B;

                &:after {
                    content: 'SALE';
                    position: absolute;
                    top: 7px;
                    left: 9px;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                    color: #FFFFFF;
                }
            }

            &.new {
                background: #4CE1B6;

                &:after {
                    font-family: 'Linearicons-Free', sans-serif;
                    content: "\e814";
                    position: absolute;
                    top: -5px;
                    margin-left: -13px;
                    left: 50%;
                    font-size: 26px;
                    color: #FFFFFF;
                }
            }

            width: 50px;
            height: 40px;
            position: relative;
            margin-right: 5px;

            &::before {
                content: '';
                display: inline-block;
                position: absolute;
                width: 0;
                height: 0;
                bottom: 0;
                border-left: 25px solid transparent;
                border-right: 25px solid transparent;
                border-bottom: 10px solid $secondary-color;
            }
        }
    }

    .name {
        font-size: 16px;
        line-height: 24px;
        height: 24px;
        white-space: nowrap;

        &.open {
            border-bottom: 1px dashed $dark-color;
        }
    }

    .value {
        font-size: 12px;
        line-height: 18px;
        flex-direction: column;
        align-items: flex-end;
        text-align: end;

        a {
            color: $text-color;
        }

        .color {
            height: 14px;
            width: 13px;
            margin-right: 11px;
        }
    }

    .additional {
        font-size: 12px;
        line-height: 18px;
    }

    .price {
        font-size: 18px;
        line-height: 27px;
    }

    .old-price {
        color: rgba(0, 0, 0, 0.5);
        text-decoration: line-through;
        margin-top: 10px;
    }

    .price-info {
        text-align: right;
        margin-left: 30px;
        line-height: 25px;
    }

    .services, .delivery {
        .currency {
            height: 18px;
        }
    }

    .cart-button-container {
      min-height: 100px;
    }

    .cart-button {
        height: 43px;
        font-size: 14px;
        line-height: 21px;
        margin-left: auto;

        &--loading {
          margin-left: 30px;
        }
    }

    .offer-name {
        font-size: 16px;
        line-height: 24px;
    }

    .files {
        .image {
            width: 90px;
            height: 63px;
            background: #C4C4C4;
            color: #000000;
            font-size: 40px;
        }

        .title {
            @include ellipsis(90px);
            font-size: 10px;
            line-height: 15px;
            color: $text-color;
        }
    }

    .btn-plus {
        background: transparent;
        border: 1px solid $primary-color;
        width: 30px;
        height: 30px;
        span {
            font-size: 20px;
        }
    }
    .item-cart-success.show {
        visibility: visible;
    }
    .item-cart-success {
        display: flex;
        flex-direction: column;
        width: 25%;
        margin-right: 0;
        margin-left: auto;
        align-items: center;
        visibility: hidden;

        .lnr {
            font-size: 25px;
            color: #8BC34A;
        }
    }
    .inverse-btn {
        background: $primary-color;
        .lnr {
            color: #FFFFFF!important;
        }
    }
    .lnr.lnr-plus-circle {
        width: 18px;
    }

    .attribute-select-wrapper {
      width: 100%;
    }

    .attribute-select {
      height: 40px;
      background-color: #FFFFFF;
      font-size: 16px;
      line-height: 24px;

      &__placeholder {
        justify-content: space-between;
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }

      &__placeholder-without-selection {
          
        & > div:first-child {
            border-color: $danger-color;
            color: $danger-color;
        }

        border: 1px solid $danger-color !important;

        & div.arrow {
            color: $danger-color;
        }
      }
    }

    &__column--right {
      display: flex;
      flex-direction: column;

      .sticky-container {
        position: sticky;
        top: 62px;
      }
    }

    &__info {
      padding-right: 20px;

      &>div:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    .product-stock__details {
      color: $primary-color;
    }

  }

.outlets-tooltip {
    width: 537px;
    max-height: 643px;
    overflow-y: auto;
    font-size: 12px;
    line-height: 18px;
    padding-left: 28px;
    padding-right: 21px;
    overflow-x: hidden;

    .outlet-item {
      display: grid;
      grid-template-columns: auto max-content;
      align-items: end;
      gap: 0 .25rem;
      margin-bottom: 5px;

      &__title {
        position: relative;
        overflow: hidden;

        &::after {
          content: repeatChar('.', 90);
          position: absolute;
        }
      }
    }
}

.outlets-container {

  &.tooltip.show {
    opacity: 1;
  }

  &--margin-1 {
    margin-top: -40px;
  }

  &--margin-2 {
    margin-top: -58px;
  }

  &--margin-3 {
    margin-top: -76px;
  }
}