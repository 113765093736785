@import "../variables";

$miniImageHeight: 63px;

.carousel {
    .item {
        width: 190px;
        margin: 0 auto;
    }

    &.mini {
        .item {
            width: 90px;
            margin: 0;

            a {
                color: $text-color;
                text-decoration: none;
            }

            img {
                width: 100%;
                height: $miniImageHeight;
                box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            }

            .product-name {
                width: 100%;
                font-size: 10px;
                line-height: 15px;
                margin-top: 3px;
            }
        }

        .swiper-button-prev, .swiper-button-next {
            height: $miniImageHeight;
            top: 0;
        }

        .swiper-button-prev {
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%);
        }

        .swiper-button-next {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%, rgba(255, 255, 255, 1) 100%);
        }
    }

    .swiper-button-prev:after, .swiper-button-next:after {
        font-size: 13px;
        font-weight: bold;
        color: $dark-color;
    }

    .swiper-button-prev {
        left: 0;
    }

    .swiper-button-next {
        right: 0;
    }

    .swiper-button-prev, .swiper-button-next {
        margin-top: 0;
        top: 90%;
    }

    .swiper-button-disabled {
        display: none;
    }
}
