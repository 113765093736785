@import "../variables";

.fabrics {
    position: relative;
    min-height: 100px;

    .nav-tabs {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 102;
        font-size: 14px;
    }

    &-category {
        padding: 30px 0 20px 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);;

        &:last-child {
            border-bottom: none;
        }

        &-name {
            line-height: 24px;
            margin-bottom: 10px;
        }

        &-item {
            img {
                width: 120px;
                height: 120px;
            }

            .color-name {
                font-size: 12px;
                line-height: 18px;
                padding-top: 5px;
            }

            .name {
                font-size: 10px;
                line-height: 15px;
            }
        }

    }
}
