.askona-widget-payment {
    width: 315px;
    height: 540px;
    background: #FAFAFA;
    color: #444444;
    border-radius: 5px;
    padding: 10px;
    font-size: 14px;
    line-height: 21px;
    position: relative;

    .close {
        position: absolute;
        right: 7px;
        top: 2px;
        cursor: pointer;
    }

    &__title {
        text-transform: uppercase;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__block {
        background: #F6F6F6;
        border-radius: 5px;
        padding: 10px 20px;
        margin-top: 12px;

        &.margin {
            margin-bottom: 70px;
        }

        > div:first-child {
            line-height: 32px;
            text-transform: uppercase;
        }

        .amount {
            font-size: 16px;
            font-weight: bold;
        }

        .form-group {
            input {
                border-radius: 0;
                border-color: #27979D;
                margin-top: 15px;
            }
        }
    }

    .button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;

        button {
            width: 150px;
            height: 36px;
            line-height: 12px;
        }

        &.margin {
            margin-top: 30px;
        }
    }

    &__notify {
        background: #EEEEEE;
        border-radius: 5px;
        padding: 10px 20px;
        text-align: center;

        &.big {
            margin-top: 45px;
        }

        .icon {
            img {
                width: 66px;
                height: 66px;
                opacity: 0.3;
                margin: 40px;
            }

            &.spin img {
                animation: spin 4s linear infinite;
            }
        }
    }

    &__amount {
        font-size: 28px;
        line-height: 41px;
        color: #27979D;
    }

    .select {
        font-size: 14px;
        height: 36px;
        max-width: 165px;
        padding-right: 30px;
        border: 1px solid #27979D;

        .dropdown {
            width: 100%;
            top: calc(100% + 1px);
            border-radius: 0;
            background: #FAFAFA !important;
        }

        .arrow {
            width: 30px;
            height: 36px;
            border-radius: 0 5px 5px 0;
            border: 0;
            border-left: 1px solid #27979D;
            position: absolute;
            right: 0;
            margin: 0;

            &:before {
                content: "\e874";
                font-family: 'Linearicons-Free', sans-serif;
                width: 10px;
                height: 10px;
                font-size: 10px;
                position: absolute;
                top: 50%;
                left: 50%;
                margin-top: -7px;
                margin-left: -5px;
            }
        }

        &.open {
            border-radius: 5px;

            .arrow {
                background: #27979D;

                &:before {
                    color: #FFFFFF;
                }
            }
        }
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
