@import "variables";

@mixin ellipsis($maxWidth: 100%) {
    min-width: 0;
    max-width: $maxWidth;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin respond($breakpoint) {
  @if $breakpoint == tab-port {
    // md
    @media only screen and (max-width: 850px) {
      @content;
    }
  }

  @if $breakpoint == desktop {
    // xl
    @media only screen and (min-width: 1200px) {
      @content;
    }
  }
}