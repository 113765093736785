@import '../../styles/variables';

.materialItem {
  width: 120px;
}

.materialImgWrapper {
  border: 1px solid transparent;
}

.materialImgWrapperSmall {
  width: 15px;
}

.materialActive {
  border: 3px solid $primary-color;
}
