.input {
  min-height: 55px;
  position: relative;
  padding-bottom: 20px;
}

.img {
  width: 75px;
}

.errorMessage {
  position: absolute;
}

.shortInput {
  position: relative;
}

.shortInput.withError {
  padding-bottom: 10px;
}
