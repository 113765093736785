@import "../../styles/variables";

.img {
  width: 100%;
  object-fit: cover;
}

.imgWrapper {
  width: 70px;
  min-height: 44px;
  margin-right: 15px;
}

.infoRow {
  display: flex;
  flex-grow: 2;
  justify-content: space-between;
  .masterProductName {
    width: 100%;
  }
}

.product {
  border-bottom: 1px solid $light-grey-color;
}

.tr {
  border-bottom: 2px solid $light-grey-color;
}

.services {
  border-bottom: 1px solid $light-grey-color;
}

.contentInMarkedContainer {
  background-color: $warning-color;
  color: #FFFFFF;
  width: fit-content;
  padding: 2px 18px;
  height: 24px;
  line-height: 22px;
  border-radius: 15px;
}

.contactName {
  color: $primary-color;
}
