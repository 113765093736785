@import "../../styles/variables";

.notificationList{
  position: fixed;
  right: 25px;
  top: 75px;
  z-index: 10;
}

.notificationHistory{
  position: fixed;
  left: 240px;
  top: $header-height;
  z-index: 10;
}

.notificationField {
  width: 460px;
  min-width: 300px;
  z-index: 10;
  max-height: 500px;
  overflow-y: auto;
}

.descriptionField {
  flex-grow: 1;
  padding: 25px 0 25px 25px;
}

.notificationIcon {
  font-size: 25px;
  line-height: 30px;
}

.notificationDescription {
  font-size: 16px;
  line-height: 24px;
}

.notificationCloser {
  min-width: 30px;
}

.notificationDarkBackground {
  background-color: $gray-color;
}

.notificationMediumBackground {
  background-color: $notification-new-color;
}

.notificationLightBackground {
  background-color: white;
}

.countPosition {
  top: -3px;
  right: -1px;
}

.scrolled {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 500px;
  max-width: 460px;
}

.scrollBar::-webkit-scrollbar {
  width: 8px;
  background-color: $light-color;
}

.scrollBar::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: $dark-color;
}

.scrollBar::-webkit-scrollbar-track {
  background-color: $light-color;
}

.scrollBar::-webkit-scrollbar-button {
  display: none;
}

.notificationHeightDecreaser {
  max-height: 0;
  transition: all 1s;
}
