@import "linearicons";

@font-face {
    font-family: 'Circe';
    src: url('subset-Circe-Regular.eot');
    src: url('subset-Circe-Regular.eot?#iefix') format('embedded-opentype'),
    url('subset-Circe-Regular.woff2') format('woff2'),
    url('subset-Circe-Regular.woff') format('woff'),
    url('subset-Circe-Regular.ttf') format('truetype'),
    url('subset-Circe-Regular.svg#Circe-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circe';
    src: url('subset-Circe-Light.eot');
    src: url('subset-Circe-Light.eot?#iefix') format('embedded-opentype'),
    url('subset-Circe-Light.woff2') format('woff2'),
    url('subset-Circe-Light.woff') format('woff'),
    url('subset-Circe-Light.ttf') format('truetype'),
    url('subset-Circe-Light.svg#Circe-Light') format('svg');
    font-weight: 350;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Circe';
    src: url('subset-Circe-Bold.eot');
    src: url('subset-Circe-Bold.eot?#iefix') format('embedded-opentype'),
    url('subset-Circe-Bold.woff2') format('woff2'),
    url('subset-Circe-Bold.woff') format('woff'),
    url('subset-Circe-Bold.ttf') format('truetype'),
    url('subset-Circe-Bold.svg#Circe-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
