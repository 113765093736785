.askonaWidget-loyaltyProgram {
    width: 315px;
    height: 542px;
    background: #FAFAFA;
    color: #444444;
    border-radius: 5px;
    padding: 10px 23px;
    font-size: 14px;
    line-height: 21px;
    position: relative;

    input.error {
        color: #F44336;
        border-color: #F44336;
    }

    &-close {
        position: absolute;
        right: 7px;
        top: 2px;
        cursor: pointer;
    }

    &-title {
        text-transform: uppercase;
        height: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &-select {
        margin: 15px 0;

        .select {
            font-size: 14px;
            height: 36px;
            max-width: 180px;
            padding-right: 30px;
            text-transform: uppercase;
            border: 1px solid #2196F3;
            margin: 0 auto;

            .dropdown {
                width: 100%;
                top: calc(100% + 1px);
                border-radius: 0;
                background: #FAFAFA !important;
            }

            .arrow {
                width: 30px;
                height: 36px;
                border-radius: 0 5px 5px 0;
                border: 0;
                border-left: 1px solid #2196F3;
                position: absolute;
                right: 0;
                margin: 0;

                &:before {
                    content: "\e874";
                    font-family: 'Linearicons-Free', sans-serif;
                    width: 10px;
                    height: 10px;
                    font-size: 10px;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    margin-top: -7px;
                    margin-left: -5px;
                }
            }

            &.open {
                border-radius: 5px;

                .arrow {
                    background: #2196F3;

                    &:before {
                        color: #FFFFFF;
                    }
                }
            }
        }
    }

    &-phone {
        display: flex;
        justify-content: space-between;
    }

    .text-thin {
        font-weight: 350;
    }

    &-balance {
        text-align: center;
        font-size: 12px;
        line-height: 18px;

        &-bonus {
            font-size: 28px;

            line-height: 41px;
            color: #2196F3;
            margin-top: 15px;
        }

        &-amount {
            font-size: 28px;
            line-height: 41px;
            color: #8BC34A;
            margin-top: 15px;
        }
    }

    &-withdrawal {
        background: #BBBBBB;
        border-radius: 5px;
        padding: 15px;
        text-align: center;
        margin-top: 15px;
        height: 130px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        input, button {
            width: 100px;
            height: 36px;
            text-align: center;
            margin-top: 15px;
        }

        button:last-child {
            margin-left: 20px;
        }

        &-label {
            white-space: pre-line;
            line-height: 15px;
        }

        &-form {
            form, >div {
                display: flex;
                justify-content: center;
            }
        }
    }

    &-change-btn {
        margin-top: 15px;
        display: flex;
        justify-content: center;

        button {
            height: 36px;
        }
    }

    &-error {
        font-size: 16px;
        text-align: center;
        color: #F44336;
        margin: 30px 0;
    }

    &-notFound {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 -13px;

        &-info {
            background: #EEEEEE;
            border-radius: 5px;
            padding: 15px;
            margin: 65px 0 45px;
        }

        button {
            width: 150px;
            height: 36px;
        }
    }

    &-enterCard {
        background: #C4C4C4;
        border-radius: 5px;
        padding: 20px;
        line-height: 15px;
        margin-top: 40px;

        &-title {
            height: 41px;
            text-align: center;
        }

        &-hint {
            height: 41px;
            font-weight: 350;
            text-align: center;
        }

        &-form {
            form {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 10px;

                input {
                    text-align: center;
                    width: 214px;
                    height: 36px;
                }

                button {
                    width: 100px;
                    height: 36px;
                }
            }
        }
    }

    &-skip {
        display: flex;
        justify-content: center;
        bottom: 10px;
        left: 0;
        width: 100%;
        position: absolute;
    }
    &-modal.modal-backdrop {
        background-color: #c4c4c4;
    }

    &-modal-content.modal-content {
         width: 270px;
         height: 130px;
         border-radius: 5px;
         padding: 10px;

        button {
            width: 86px;
            margin: 10px;
        }
     }

    .modal-dialog {
        display: flex;
        justify-content: center;
    }
}
