@import '../variables';
@import '../mixins';

.constructor {
  font-size: 16px;
  height: calc(100% - 50px);

  &--with-steps {
    height: calc(100% - 94px - 1.5rem);
  }

  &__summary {
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .title {
      line-height: normal;
    }

    .price {
      line-height: normal;
    }
  }

  &__step-buttons {
    & .btn {
      border-width: 2px;
      font-weight: bold;
    }

    & .btn:disabled {
      opacity: 1;
    }

    & .btn-outline-primary:disabled {
      color: #444;
    }
  }

  &__swatch-modal {
    max-width: 745px;
    height: auto;
    top: inherit;
    margin: 1.75rem auto;

    .modal-header {
      border-bottom: none;
      padding-bottom: 0;
    }

    .modal-body {
      max-height: calc(100vh - 200px);
      overflow: auto;

      .unrecommended {
        display: flex;
        align-items: center;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 20px;
        color: #999999;

        &__dash {
          margin-left: 8px;
        }

        &__clarification {
          margin-left: 8px;
        }
      }
    }

    .modal-body::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    .modal-body::-webkit-scrollbar:vertical {
      width: 11px;
    }

    .modal-body::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid #fff;
      background-color: rgba(0, 0, 0, 0.5);
    }

    .modal-content {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-radius: 0.3rem;
      outline: 0;
      padding: 0;
      text-align: inherit;
    }
  }

  &__swatch-element {
    cursor: pointer;
    width: 132px;

    .wrapper {
      position: relative;
      padding: 6px;
      width: 120px;
      height: 120px;

      .unrecommended-icon {
        position: absolute;
        top: 8px;
        right: 8px;
        z-index: 2;
      }

      &.selected {
        border: 3px solid $primary-color;
        padding: 3px;

        .unrecommended-icon {
          top: 5px;
          right: 5px;
        }
      }
    }

    .image {
      width: 100%;
      height: 100%;
    }

    &__small {
      .image {
        width: 24px;
        height: 24px;
      }
    }
  }

  &__category-step {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  &__nav-buttons-container {
    margin-top: auto;
  }
}
