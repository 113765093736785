@import "../variables";

.tooltip-inner {
  color: inherit;
  background-color: inherit;
  max-width: none;
  padding: 0;
}

.askona-tooltip {
  padding: 30px;
  border-radius: 5px;
  position: relative;

  .close {
    color: $dark-color;
    font-size: 23px;
    position: absolute;
    right: 10px;
    top: 10px;
  }
}
