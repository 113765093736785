@import "../variables";

.date-picker {
    width: 100%;

    & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
        width: 100%;
    }
    .form__form-group-datepicker {
        width: 100%;
        height: 100%;

        display: flex;
        align-items: center;
    }

    .react-datepicker__time-list {
        padding: 0;
        overflow-x: hidden;
    }

    .react-datepicker__time-list-item {
        width: 50px;
    }

    .react-datepicker__time {
        overflow: hidden;
    }

    &.date-picker--interval {
        display: flex;

        & > div, .react-datepicker-wrapper, .react-datepicker__input-container {
            max-width: 170px;
        }
    }

    .date-picker__svg {
        width: 14px;
        height: 14px;
        margin: auto 10px;
    }

    .react-datepicker__header {
        background-color: $primary-color;
        border-color: $primary-color;
        border-radius: 0;
    }

    .react-datepicker__current-month, .react-datepicker-time__header,
    .react-datepicker__day-name, .react-datepicker__time-name {
        color: #ffffff;
    }

    .react-datepicker__navigation--next {
        border-left-color: #ffffff;

        &:hover {
            border-left-color: #ffffff;
        }
    }

    .react-datepicker__navigation--previous {
        border-right-color: #ffffff;

        &:hover {
            border-right-color: #ffffff;
        }
    }

    .react-datepicker__day--keyboard-selected {
        background-color: $primary-color;
        transition: all 0.3s;

        &:hover {
            background-color: $primary-color;
        }
    }

    .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
        border-bottom-color: $primary-color;
        margin-top: -7px;
    }

    .react-datepicker {
        box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.05);
        border: none;
        display: inline-flex;
    }

    .react-datepicker__time-container {
        width: 85px!important;
    }
    .react-datepicker__time-box {
        width: 85px!important;
    }

    .react-datepicker__day--selected, .react-datepicker__day--in-range, .react-datepicker__time-list-item--selected {
        background-color: $primary-color !important;
    }

    .react-datepicker__year-read-view--selected-year {
        color: white;
    }
}
