@import "../variables";

.modal-dialog {
    $height: 384px;
    max-width: 385px;
    height: $height;
    top: 50%;
    margin-top: -($height / 2);
}

.modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
}

.modal__close-btn {
    position: absolute;
    top: 15px;
    right: 15px;
    font-size: 14px;
    width: 14px;
    height: 14px;
    cursor: pointer;
    padding: 0;
    border: none;
    background: transparent;
}

.modal__title-icon {
    width: 24px;
    height: 24px;
    font-size: 24px;
}

.modal__title {
    margin-top: 10px;
    margin-bottom: 15px;

    &:first-child {
        margin-top: 0;
    }
}

.modal__footer {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 0;

    button {
        min-width: 100px;
        padding: 4px 25px;
        margin-bottom: 0;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }

}

.modal-dialog--primary {

    .modal__title-icon {
        color: $primary;
    }
}

.modal-dialog--success {

    .modal__title-icon {
        color: $success;
    }
}

.modal-dialog--warning {

    .modal__title-icon {
        color: $warning;
    }
}

.modal-dialog--danger {

    .modal__title-icon {
        color: $danger;
    }

    .btn-danger {
        background: $danger;
    }
}

.modal-dialog--header {
    max-width: 520px;

    .modal-content {
        padding: 0;
    }

    .modal__header {
        color: white;
        padding: 15px 20px;
        position: relative;
    }

    .modal__title {
        margin: 0;
        font-weight: 300;
    }

    .modal__close-btn {
        color: white;
        top: calc(50% - 8px);
    }

    .modal__body {
        padding-top: 25px;
        padding-bottom: 20px;
    }

    .modal__footer {
        margin-bottom: 40px;
        justify-content: flex-end;
    }

    &.modal-dialog--primary .modal__header {
        background-color: $primary;
    }

    &.modal-dialog--success .modal__header {
        background-color: $success;
    }

    &.modal-dialog--warning .modal__header {
        background-color: $warning;
    }

    &.modal-dialog--danger .modal__header {
        background-color: $danger;
    }
}

.modal-dialog--full-width {
    width: 100VW;
    max-width: 100VW;
    height: 100VH;
    top: 0;
    margin-top: 0;

    .modal-content {
        height: 100%;
    }
}

.modal-dialog--large-size {
    width: 600px;
    max-width: 600px;
}

.modal-dialog--top {
    top: 10%;
    margin-top: 0;
}