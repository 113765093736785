@import "../../styles/variables";

.errorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.errorIcon {
  margin-top: 25%;
}

.errorTitle {
  font-size: 24px;
  line-height: 36px;
  font-weight: bold;
  color: $danger-color;
  margin-top: 16px;
}

.errorText {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: $text-color;
  text-align: center;
  margin-top: 8px;
}